module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-image@2.6.0_@babel+core@7.16.7_gatsby-plugin-sharp@4.14.1_gatsby-source-filesys_f43ylrkd2tyr4gkpk26u6kvpfa/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@6.7.0_gatsby-plugin-sharp@4.14.1_gatsby@4.7.1/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@4.25.0_gatsby@4.7.1_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N7Z8HP25","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.5.1_gatsby@4.7.1_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#fff","display":"minimal-ui","icon":"src/images/icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"21124f5338b787b68c0ac65b26819ff7"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@5.5.0_babel-plugin-styled-components@2.0.2_gatsby@4.7.1_react_ailw76b7dzopdxt62lg4ey6imq/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/@sentry+gatsby@6.17.3_gatsby@4.7.1_react@17.0.2/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://ecf72d6d260a4af98e5a5c352e4cfbdb@o1133288.ingest.sentry.io/6179967","sampleRate":1,"environment":"prod","release":"f705a1dd9aa6165c036e390c1634e390b209cc9e","ignoreErrors":[{}]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-canonical-urls@4.6.0_gatsby@4.7.1/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://bookphysio.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
