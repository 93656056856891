exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-advertising-policy-tsx": () => import("./../../../src/pages/advertising-policy.tsx" /* webpackChunkName: "component---src-pages-advertising-policy-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-clinic-registration-tsx": () => import("./../../../src/pages/clinic-registration.tsx" /* webpackChunkName: "component---src-pages-clinic-registration-tsx" */),
  "component---src-pages-content-disclaimer-tsx": () => import("./../../../src/pages/content-disclaimer.tsx" /* webpackChunkName: "component---src-pages-content-disclaimer-tsx" */),
  "component---src-pages-editorial-policy-tsx": () => import("./../../../src/pages/editorial-policy.tsx" /* webpackChunkName: "component---src-pages-editorial-policy-tsx" */),
  "component---src-pages-generate-api-key-guide-tsx": () => import("./../../../src/pages/generate-api-key-guide.tsx" /* webpackChunkName: "component---src-pages-generate-api-key-guide-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/articleTemplate.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-author-template-tsx": () => import("./../../../src/templates/authorTemplate.tsx" /* webpackChunkName: "component---src-templates-author-template-tsx" */),
  "component---src-templates-booking-tsx": () => import("./../../../src/templates/booking.tsx" /* webpackChunkName: "component---src-templates-booking-tsx" */),
  "component---src-templates-clinic-template-tsx": () => import("./../../../src/templates/clinicTemplate.tsx" /* webpackChunkName: "component---src-templates-clinic-template-tsx" */),
  "component---src-templates-conditions-tsx": () => import("./../../../src/templates/conditions.tsx" /* webpackChunkName: "component---src-templates-conditions-tsx" */),
  "component---src-templates-contact-us-tsx": () => import("./../../../src/templates/contact-us.tsx" /* webpackChunkName: "component---src-templates-contact-us-tsx" */),
  "component---src-templates-editorial-team-tsx": () => import("./../../../src/templates/editorial-team.tsx" /* webpackChunkName: "component---src-templates-editorial-team-tsx" */),
  "component---src-templates-index-template-tsx": () => import("./../../../src/templates/indexTemplate.tsx" /* webpackChunkName: "component---src-templates-index-template-tsx" */),
  "component---src-templates-injuries-tsx": () => import("./../../../src/templates/injuries.tsx" /* webpackChunkName: "component---src-templates-injuries-tsx" */),
  "component---src-templates-pain-tsx": () => import("./../../../src/templates/pain.tsx" /* webpackChunkName: "component---src-templates-pain-tsx" */),
  "component---src-templates-patient-resources-tsx": () => import("./../../../src/templates/patient-resources.tsx" /* webpackChunkName: "component---src-templates-patient-resources-tsx" */),
  "component---src-templates-popular-clinics-tsx": () => import("./../../../src/templates/popular-clinics.tsx" /* webpackChunkName: "component---src-templates-popular-clinics-tsx" */),
  "component---src-templates-popular-locations-tsx": () => import("./../../../src/templates/popular-locations.tsx" /* webpackChunkName: "component---src-templates-popular-locations-tsx" */),
  "component---src-templates-sitemap-template-tsx": () => import("./../../../src/templates/sitemapTemplate.tsx" /* webpackChunkName: "component---src-templates-sitemap-template-tsx" */),
  "component---src-templates-team-tsx": () => import("./../../../src/templates/team.tsx" /* webpackChunkName: "component---src-templates-team-tsx" */)
}

