require('./src/css/global.css');

import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_RECAPTCHA_ENTERPRISE_KEY}
      language='en'
      // useRecaptchaNet
      useEnterprise
      scriptProps={{
        // async: true,
        // defer: true,
        // appendTo: 'body',
        id: 'recaptcha',
      }}
    >
      {element}
    </GoogleReCaptchaProvider>
  )
}